
// Firefox does not scroll to map items when the links at top of the page is clicked.
// This fix forces to go to the right place.

(() => {
  const isFirefox = navigator.userAgent.toLowerCase().includes("firefox");
  if (!isFirefox) return;
  function showMapInfo(clickEvt) {
    const mapInfoCardId = clickEvt.target.getAttribute("href")?.substr(1);
    if (!mapInfoCardId) return;

    const mapInfoCard = document.getElementById(mapInfoCardId);
    mapInfoCard?.scrollIntoView(true);
  }

  const mapsInfoLinks = document.querySelectorAll(".location-links a");
  mapsInfoLinks.forEach((mapInfo) => {
    mapInfo.addEventListener("click", showMapInfo);
  });
})();
